import React, { useContext } from 'react';
import { BASE_URL } from '../../BaseUrl';
//functions
import {
    _t,
    getSystemSettings,
    pageLoading
} from "../../../src/functions/Functions";

//   context value
import { SettingsContext, loading, setLoading } from "../../contexts/Settings";

const Banner = () => {
    //getting context values here
    let { templateOneSettings, loading } =
        useContext(SettingsContext);

    return (
        <>{
            loading ? pageLoading() : (
                <section class="banner-area padding-top-100 padding-bottom-150">
                    <div class="banner-shapes">

                        <span class="b-shape-1">  <img src="/website/images/img/5.png" alt="" /></span>
                        <span class="b-shape-2"><img src="/website/images/img/6.png" alt="" /></span>
                        <span class="b-shape-3"><img src="/website/images/img/7.png" alt="" /></span>
                        <span class="b-shape-4"><img src="/website/images/img/9.png" alt="" /></span>
                        <span class="b-shape-5"><img src="/website/images/shapes/18.png" alt="" /></span>
                    </div>
                    <div class="container padding-top-145">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-md-12 col-lg-6 col-xl-6">
                                <div class="banner-content">
                                    <h1>{getSystemSettings(templateOneSettings, "heroTitle")}</h1>
                                    {/* <h1>enjoy our delicious <span>food</span></h1> */}
                                    {/* // <!-- buyone-shape --> */}
                                    <div class="buyone-shape">
                                        <div class="banner-tag">
                                            {/* <h5>get your food</h5> */}
                                            <h6 className="text-white">{getSystemSettings(templateOneSettings, "heroTagTitle")}</h6>
                                        </div>
                                        {/* <span class="banner-badge">order now</span> */}
                                        <span class="banner-badge">{getSystemSettings(templateOneSettings, "heroBadgeTitle")}</span>
                                    </div>

                                    {/* // <!-- order-box --> */}
                                    <div class="order-box">
                                        <span class="order-img"><img src="/website/images/icons/1.png" alt="" /></span>
                                        <div class="order-content">
                                            <p>delivery order num.</p>
                                            <span>{getSystemSettings(templateOneSettings, "heroNumber")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-none d-lg-block col-lg-6 col-xl-6">
                                <div class="banner-img">
                                    <div class="pizza-shapes">
                                        <span class="p-shape-1"><img src="/website/images/img/2.png" alt="" /></span>
                                        <span class="p-shape-2"><img src="/website/images/img/3.png" alt="" /></span>
                                        <span class="p-shape-3"><img src="/website/images/img/4.png" alt="" /></span>
                                        <span class="p-shape-4"><img src="/website/images/img/8.png" alt="" /></span>
                                    </div>
                                    {/* <img src="/website/images/img/1.png" alt="" /> */}
                                    <img src={`${BASE_URL}/uploads/${getSystemSettings(templateOneSettings, "heroImage")}`} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }

        </>
    );
}

export default Banner;
